import React, { useContext, useState, useEffect } from "react";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import MenuPrincipal from "../../Components/Menu";
import {
  Box,
  Button,
  Text,
  Link,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Image,
} from "@chakra-ui/react";
import { SimuladoContext } from "../../contexts/Simulado";
import { MateriaContext } from "../../contexts/Materia";
import Logo from "../../images/logo.png";
import { AiOutlineScissor } from "react-icons/ai";
import { RiScissorsCutFill } from "react-icons/ri";
const SimuladorPage = () => {
  const { DetailSimuladoApi } = useContext(SimuladoContext);
  const { listQuestoesApi } = useContext(MateriaContext);

  const toast = useToast();
  const history = useNavigate();
  const [simuladoId, setSimuladoId] = useState(null);
  const [simulator, setSimulator] = useState();
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isAnswerCorrect, setIsAnswerCorrect] = useState({});
  const [scissorClickedMap, setScissorClickedMap] = useState({});
  const [remainingTime, setRemainingTime] = useState(14400); // Tempo em segundos (2 horas)
  const [timerActive, setTimerActive] = useState(true);
  const [isAnswerCut, setIsAnswerCut] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [logged, setLogged] = useState(null);

  const handleCutAnswer = (questionId) => {
    // Verifica se a resposta já foi cortada
    const isAlreadyCut = isAnswerCut[questionId] || false;

    // Atualiza o estado isAnswerCut para indicar se a resposta foi cortada ou não
    setIsAnswerCut((prevIsAnswerCut) => ({
      ...prevIsAnswerCut,
      [questionId]: !isAlreadyCut,
    }));

    // Lógica adicional para alterar o estado do botão, texto ou outros comportamentos
    const newSelectedAnswers = { ...selectedAnswers };
    if (isAlreadyCut) {
      delete newSelectedAnswers[questionId];
    }
    setSelectedAnswers(newSelectedAnswers);
  };

  function userLogged() {
    const user = JSON.parse(localStorage.getItem("user")); // Converte o JSON para objeto
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (user === null) {
      setLogged(null);
    } else {
      api
        .get(`/user/${user.id}/status`, { headers })
        .then((response) => {
          setLogged(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setLogged(null);
          } else {
            setLogged(null);
          }
        });
    }
  }

  const decreaseTime = () => {
    setRemainingTime((prevTime) => prevTime - 1);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    history("/");

    toast({
      title: "Você precisa estar logado para realizar o simulado online",
      status: "error",
      isClosable: true,
    });
  };
  async function getSimuladoInfo() {
    const simuladoID = localStorage.getItem("difficulty_id");
    const simuladoDay = localStorage.getItem("simulate_day");
    try {
      const simuladoInfoApi = await DetailSimuladoApi(simuladoID, simuladoDay);
      setSimulator(simuladoInfoApi.data?.simulator);
      setQuestions(simuladoInfoApi.data?.questions);
    } catch (error) {
      if (error.response?.status === 401) {
        onOpen();
      }
      console.log(error);
    }
  }

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await api.post("auth/login", data);

      const loggedUser = response.data.user;
      const token = response.data.token;

      localStorage.setItem("user", JSON.stringify(loggedUser));
      localStorage.setItem("token", token);

      setUser(loggedUser);
      setIsLoggedIn(true);
      if (response.status === 200) {
        toast({
          title: "Usuário Logado!",
          description: "Aproveite nosso sistema.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsLoggedIn(true);
        onClose();
      }
      return response;
    } catch (error) {
      JSON.stringify(error);
      console.log(error.data);
      toast({
        title: "Verifique o email e senha",
        status: "error",
        isClosable: true,
      });
      return error;
    }
  };

  const handleAnswerSelection = (questionId, answerId) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: answerId,
    }));
  };

  useEffect(() => {
    getSimuladoInfo();
    userLogged();
  }, []);
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    if (remainingTime > 0 && timerActive) {
      const timerId = setTimeout(decreaseTime, 1000); // 1000 ms = 1 segundo
      return () => clearTimeout(timerId);
    } else if (remainingTime === 0 && timerActive) {
      // Aqui você pode fazer alguma ação quando o tempo limite for atingido.
      // Por exemplo, enviar as respostas automaticamente após o tempo acabar.
      handleEnviarRespostas();
      setTimerActive(false); // Para evitar que a função seja chamada várias vezes após o tempo limite.
    }
  }, [remainingTime, timerActive]);

  const prepareAnswersObject = () => {
    const currentDate = formatDate(new Date());
    const difficulty = localStorage.getItem("difficulty_id");
    const answersArray = questions.map((question, index) => {
      const questionId = question.id;
      const selectedAnswerId = selectedAnswers[questionId];
      const selectedAnswer = question.answers.find(
        (answer) => answer.id === selectedAnswerId
      );

      return {
        question_id: questionId,
        answer: selectedAnswer ? selectedAnswer.id : null,
      };
    });

    return {
      simulator: answersArray,
      difficulty: difficulty,
      day: currentDate,
    };
  };

  const handleEnviarRespostas = async () => {
    const answersObject = prepareAnswersObject();

    // Verifique se todas as perguntas foram respondidas
    let allAnswered = true;
    let firstUnansweredQuestionId = null;
    questions.forEach((question) => {
      if (!selectedAnswers[question.id]) {
        allAnswered = false;
        if (!firstUnansweredQuestionId) {
          firstUnansweredQuestionId = question.id;
        }
      }
    });

    if (!allAnswered) {
      // Exiba uma mensagem de erro
      toast({
        title: "Por favor, responda todas as perguntas antes de enviar.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }
    // console.log("Objeto para enviar à API:", answersObject);

    const token = localStorage.getItem("token");
    try {
      const response = await api.post("send/simulator", answersObject, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json;charset=utf-8",
        },
      });

      if (response.status === 200) {
        toast({
          title:
            "Simulado realizado com Sucesso, o resultado já está na sua área de aluno",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title:
          "Não foi possível realizar o simulado, verifique se todas as questões estão preenchidas",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setTimeout(function () {
      // Navegar para a página "/"
      window.location.href = "/";
    }, 2000);
    // Agora você pode enviar o objeto answersObject para a API usando as chamadas apropriadas.
    // Por exemplo, usando fetch() ou axios.post() para enviar os dados.

    // Exemplo de como enviar usando axios:
    // axios.post('url_da_api', answersObject)
    //   .then(response => {
    //     // Tratar a resposta da API, se necessário
    //   })
    //   .catch(error => {
    //     console.error('Erro ao enviar dados para a API:', error);
    //   });
  };

  return (
    <>
      <MenuPrincipal />

      <Box
        bg="#050f40"
        h="80px"
        display="flex"
        justifyContent={"center"}
        color={"white"}
        textAlign={"center"}
      >
        <Heading>Simulado Online</Heading>
      </Box>
      <Box display={"flex"} justifyContent={"center"} mt={5}>
        <Box
          backgroundColor={"white"}
          width={"90vw"}
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
        >
          <Text fontWeight={"bold"} textAlign={"center"} padding={8}>
            Cronômetro: {Math.floor(remainingTime / 3600)}:
            {Math.floor((remainingTime % 3600) / 60)}:{remainingTime % 60}
          </Text>
        </Box>
      </Box>

      {questions.map((question, index) => {
        const questionId = question.id;
        const correctAnswerIndex = question.answers?.findIndex(
          (answer) => answer.isCorrect
        );

        return (
          <>
            <Box key={index} my={4} width={"90vw"} mx="auto">
              <Box
                borderWidth="1px"
                borderRadius="md"
                p={4}
                width={"100%"}
                color={"black"}
                backgroundColor={"white"}
              >
                <Text fontWeight="bold" mb="2">
                  Questão {index + 1}:
                </Text>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: question?.question,
                  }}
                />

                {question?.answers.map((answer, index) => {
                  const isAnswerSelected =
                    selectedAnswers[question.id] === answer.id;

                  return (
                    <Box
                      key={answer.id}
                      display="flex"
                      alignItems="center"
                      mt={2}
                      color={"black"}
                      cursor="pointer"
                    >
                      <Box
                        ml={2}
                        as={
                          scissorClickedMap[answer.id]
                            ? RiScissorsCutFill
                            : AiOutlineScissor
                        }
                        fontSize={"20px"}
                        cursor="pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setScissorClickedMap((prevMap) => ({
                            ...prevMap,
                            [answer.id]: !prevMap[answer.id], // Invert the clicked state
                          }));
                          handleCutAnswer(answer.id);
                        }}
                        __css={{
                          animation: "scissorsAnimation 0.1s ease-in-out",
                          "@keyframes scissorsAnimation": {
                            "0%": {
                              transform: "scale(1)",
                            },
                            "50%": {
                              transform: "scale(0)",
                            },
                            "100%": {
                              transform: "scale(1)",
                            },
                          },
                        }}
                      />
                      <Button
                        ml={2}
                        p={"0px"}
                        fontSize={"14px"}
                        borderRadius={"18px"}
                        isDisabled={isAnswerCut[answer.id] || isAnswerSelected}
                        color={isAnswerSelected ? "gray" : "Black"}
                        variant={isAnswerSelected ? "solid" : "outline"}
                        colorScheme={isAnswerSelected ? "gray" : "gray"}
                        mr={2}
                        onClick={() =>
                          handleAnswerSelection(question.id, answer.id)
                        }
                        css={{
                          textDecoration: scissorClickedMap[answer.id]
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {String.fromCharCode(65 + index)}
                      </Button>
                      <Box
                        ml={2}
                        flex="1"
                        css={{
                          textDecoration: scissorClickedMap[answer.id]
                            ? "line-through"
                            : "none",
                          color: scissorClickedMap[answer.id]
                            ? "#ccc"
                            : "black",
                        }}
                        dangerouslySetInnerHTML={{ __html: answer.answer }}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </>
        );
      })}
      <Box display={"flex"} justifyContent={"center"} mt={5} mb={5}>
        <Box width={700}>
          <Button onClick={handleEnviarRespostas} mt={4} colorScheme="teal">
            Enviar Respostas
          </Button>
        </Box>
      </Box>

      {/* Modal de login */}
      <Modal isOpen={isOpen} onClose={() => handleModalClose()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button mt={5} onClick={handleLogin}>
              {" "}
              Login{" "}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SimuladorPage;
