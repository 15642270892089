import React, { useContext, useState, useEffect } from "react";
import MenuPrincipal from "../../Components/Menu";
import {
  Box,
  Button,
  Select,
  Flex,
  Link,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Image,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import Logo from "../../images/logo.png";
import api from "../../services/api";
import { useHistory, useNavigate } from "react-router-dom";
import { SimuladoContext } from "../../contexts/Simulado";
import BG from "../../images/bg-simulado.png";
const SelectSimulado = () => {
  const { listSimuladoApi, deleteSimuladoApi } = useContext(SimuladoContext);
  const navigate = useNavigate();
  const [simulados, setSimulados] = useState([]);
  const [difficultyID, setDifficultyID] = useState(1);
  const [simuladoTitle, setSimuladoTitle] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [daySimulate, setDaySimulate] = useState();
  const [logged, setLogged] = useState(null);
  const [user, setUser] = useState();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSimuladoChange = (event) => {
    setDifficultyID(event);
  };

  const handleSimuladoDayChange = (event) => {
    setDaySimulate(event);
  };

  const handleGoToSelectSimulado = () => {
    if (!isLoggedIn) {
      onOpen();
      return;
    }
    localStorage.setItem("simulate_day", daySimulate);
    localStorage.setItem("difficulty_id", difficultyID);

    navigate(`/simulado/online`);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsLoggedIn(false);
    }
    if (token) {
      setIsLoggedIn(true);
    }

    userLogged();
  }, []);

  function userLogged() {
    const user = JSON.parse(localStorage.getItem("user")); // Converte o JSON para objeto
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (user === null) {
      setLogged(null);
    } else {
      api
        .get(`/user/${user.id}/status`, { headers })
        .then((response) => {
          setLogged(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setLogged(null);
          } else {
            setLogged(null);
          }
        });
    }
  }

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await api.post("auth/login", data);

      const loggedUser = response.data.user;
      const token = response.data.token;

      localStorage.setItem("user", JSON.stringify(loggedUser));
      localStorage.setItem("token", token);

      setUser(loggedUser);
      setIsLoggedIn(true);
      if (response.status === 200) {
        toast({
          title: "Usuário Logado!",
          description: "Aproveite nosso sistema.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsLoggedIn(true);
        onClose();
      }
      return response;
    } catch (error) {
      JSON.stringify(error);
      console.log(error.data);
      toast({
        title: "Verifique o email e senha",
        status: "error",
        isClosable: true,
      });
      return error;
    }
  };

  return (
    <>
      <MenuPrincipal />

      <Grid templateColumns="repeat(2, 1fr)" gap={6} bg="#050f40">
        <GridItem>
          <Box
            bgImage={BG}
            backgroundSize="cover"
            backgroundPosition="left"
            backgroundRepeat="no-repeat"
            width="100%"
            height="100vh"
          ></Box>
        </GridItem>
        <GridItem>
          <Box
            display={"block"}
            mt={"50px"}
            width={"550px"}
            color={"white"}
            p={4}
          >
            <Heading>Faça um simulado online agora mesmo!</Heading>
          </Box>
          <Box p={4} width={"500px"}>
            <Select
              style={{ background: "#050f40", color: "white" }}
              required
              onChange={(e) => handleSimuladoChange(e.target.value)}
            >
              <option
                style={{ background: "#050f40", color: "white" }}
                value="1"
              >
                Iniciante
              </option>
              <option
                style={{ background: "#050f40", color: "white" }}
                value="2"
              >
                Intermediário
              </option>
              <option
                style={{ background: "#050f40", color: "white" }}
                value="3"
              >
                Avançado
              </option>
            </Select>
            <Text mt={5} color={"white"}>
              Selecione o dia que você quer simular:
            </Text>
            <Select
              mt={2}
              style={{ background: "#050f40", color: "white" }}
              required
              onChange={(e) => handleSimuladoDayChange(e.target.value)}
            >
              <option
                style={{ background: "#050f40", color: "white" }}
                value="1"
              >
                Dia 1
              </option>
              <option
                style={{ background: "#050f40", color: "white" }}
                value="2"
              >
                Dia 2
              </option>
            </Select>
            <Button
              mt={2}
              backgroundColor={"#FAB929"}
              color={"white"}
              onClick={handleGoToSelectSimulado}
            >
              Começar
            </Button>
          </Box>
        </GridItem>
      </Grid>

      {/* Modal de login */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Login</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Button mt={5} onClick={handleLogin}>
              {" "}
              Login{" "}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectSimulado;
