import React, { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { AuthContext } from "../contexts/Auth";
const EsqueceuSenhaModal = ({ isOpen, onClose }) => {
  const { login, loading, forgotPasswordApi } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const toast = useToast();
  const handleEnviarEmail = async () => {
    const data = {
      email: email,
    };

    try {
      const response = await forgotPasswordApi(data);
      toast({
        title: "E-mail enviado!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.log(error);
      toast("Erro ao se conectar com o servidor!", {
        autoClose: 5000,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Esqueceu a Senha</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Digite seu E-mail</FormLabel>
            <Input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleEnviarEmail}>
            Enviar E-mail
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EsqueceuSenhaModal;
