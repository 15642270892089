import api from "../services/api";
import { createContext } from "react";

export const SimuladoContext = createContext({});

export const SimuladoProvider = ({ children }) => {
  const createSimuladoApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("create/simulator", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const listSimuladoApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("list/simulators", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const DetailSimuladoApi = async (id, day) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/simulator/${id}/day/${day}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const deleteSimuladoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/simulator/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const addQuestaoSimuladoApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`add/question/simulator/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };
  const deleteQuestaoSimuladoApi = async (questionID, simulatorID) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(
      `delete/question/${questionID}/simulator/${simulatorID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  };

  return (
    <SimuladoContext.Provider
      value={{
        createSimuladoApi,
        listSimuladoApi,
        DetailSimuladoApi,
        deleteSimuladoApi,
        deleteQuestaoSimuladoApi,
        addQuestaoSimuladoApi,
      }}
    >
      {children}
    </SimuladoContext.Provider>
  );
};
