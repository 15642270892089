import { useState, useEffect, useContext, useRef } from "react";

import {
  Box,
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Grid,
  VStack,
  Textarea,
  useToast,
  useDisclosure,
  Input,
  Select,
  Flex,
  useRangeSlider,
} from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import { BookContext } from "../../contexts/Book";
import JoditEditor, { Jodit } from "../../Components/Editor";
import MenuPrincipal from "../../Components/Menu";
import MeuEditor from "../../Components/Editor/CKeditor";
const BookStudent = () => {
  const { detailBookApi, updateBookApi } = useContext(BookContext);
  const toast = useToast();
  const timeoutRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [book, setBook] = useState();
  const [bookContent, setContent] = useState();

  const bookInfo = async () => {
    const bookID = localStorage.getItem("book_id");
    try {
      const bookInfo = await detailBookApi(bookID);
      console.log(bookInfo.data);
      setBook(bookInfo.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateContent = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setContent(value);
    }, 300);
  };
  const updateBook = async (id) => {
    const data = {
      user_id: book.user_id,
      matery_id: book.matery_id,
      book: bookContent,
    };
    try {
      const bookInfo = await updateBookApi(id, data);
      console.log(bookInfo.data);
      if (bookInfo.status === 200) {
        toast({
          title: "Caderno Atualizado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    bookInfo();
  }, []);

  return (
    <>
      <MenuPrincipal />
      {book ? (
        <>
          <Box
            p={8}
            ml={user.role_id != 3 ? { base: 0, md: 0 } : { base: 0, md: 0 }}
          >
            <Heading fontSize={"18px"} color={"#050F40"}>
              Questão:
            </Heading>
            <Box
              my={5}
              fontSize={"18px"}
              dangerouslySetInnerHTML={{
                __html: book.question?.question,
              }}
            ></Box>

            <MeuEditor
              initialValue={book.book}
              onChange={handleUpdateContent}
            ></MeuEditor>

            <Button
              mt={4}
              colorScheme="teal"
              onClick={() => updateBook(book.id)}
            >
              Salvar caderno
            </Button>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default BookStudent;
