import React, { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputMask from "react-input-mask";
import { UserContext } from "../contexts/User";

const UserRegistrationModal = ({ isOpen, onClose }) => {
  const toast = useToast();

  const { createUserApi } = useContext(UserContext);

  const [userType, setUserType] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const handleCpfChange = (event) => {
    setCpf(event.target.value);
  };

  const handleBirthdateChange = (event) => {
    setBirthdate(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async () => {
    const data = {
      name: name,
      email: email,
      password: password,
      cpf: cpf,
      birthdate: birthdate,
      phone: phone,
      role_id: userType,
    };
    try {
      const createNewUser = await createUserApi(data);
      console.log(createNewUser);

      if (createNewUser.status === 200) {
        toast({
          title: "Usuário adicionado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
      // Exibir mensagem de erro ao usuário, se necessário
    }

    // Fechar o modal após o envio dos dados
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={"900px"} maxWidth={"90%"}>
        <ModalHeader>Registro de Usuário</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel>Nome</FormLabel>
              <Input type="text" value={name} onChange={handleNameChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input type="email" value={email} onChange={handleEmailChange} />
            </FormControl>

            <FormControl>
              <FormLabel>Tipo de Usuário</FormLabel>
              <Select
                value={userType}
                onChange={handleUserTypeChange}
                placeholder="Selecione"
              >
                <option value="2">Professor</option>
                <option value="3">Aluno</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>CPF</FormLabel>
              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={handleCpfChange}
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <FormControl>
              <FormLabel>Data de Nascimento</FormLabel>
              <InputMask
                mask="99/99/9999"
                value={birthdate}
                onChange={handleBirthdateChange}
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <FormControl>
              <FormLabel>Telefone</FormLabel>
              <InputMask
                mask="(99) 99999-9999"
                value={phone}
                onChange={handlePhoneChange}
              >
                {(inputProps) => <Input {...inputProps} />}
              </InputMask>
            </FormControl>
            <FormControl>
              <FormLabel>Senha</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <InputRightElement width="4.5rem">
                  <Button onClick={handleShowPassword}>
                    {showPassword ? "Ocultar" : "Mostrar"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel>Confirmar Senha</FormLabel>
              <InputGroup>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                <InputRightElement width="4.5rem">
                  <Button onClick={handleShowConfirmPassword}>
                    {showConfirmPassword ? "Ocultar" : "Mostrar"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
            Registrar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserRegistrationModal;
