import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  Input,
  Button,
  useToast,
  Box,
  Select,
} from "@chakra-ui/react";
import { SimuladoContext } from "../contexts/Simulado";
import { MateriaContext } from "../contexts/Materia";

const AdicionarSimuladoModal = ({ isOpen, onClose, onAdd }) => {
  const { createSimuladoApi } = useContext(SimuladoContext);
  const { listQuestoesApi } = useContext(MateriaContext);
  const [nomeSimulado, setNomeSimulado] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);

  const toast = useToast();

  useEffect(() => {
    getQuestoes();
  }, []);

  async function getQuestoes() {
    try {
      const questoesRes = await listQuestoesApi();
      setQuestionsList(questoesRes.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectQuestion = (questionId, questionText) => {
    const isSelected = selectedQuestions.some(
      (question) => question.id === questionId
    );
    if (isSelected) {
      setSelectedQuestions(
        selectedQuestions.filter((question) => question.id !== questionId)
      );
    } else {
      setSelectedQuestions([
        ...selectedQuestions,
        { id: questionId, question: questionText },
      ]);
    }
  };

  const handleRemoveQuestion = (questionId) => {
    setSelectedQuestions(
      selectedQuestions.filter((question) => question.id !== questionId)
    );
  };

  const handleSubmit = async () => {
    const data = {
      title: nomeSimulado,
      questions: selectedQuestions.map((questionId) => ({
        question_id: questionId,
      })),
    };

    try {
      const simulado = await createSimuladoApi(data);
      if (simulado.status === 200) {
        toast({
          title: "Simulado criado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }

    setNomeSimulado("");
    setSelectedQuestions([]);
    onClose();
  };

  return (
    <>
      {questionsList && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent width={"1200px"} maxW={"90%"}>
            <ModalHeader>Adicionar Simulado</ModalHeader>
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Text>Selecione as Questões:</Text>
                <Input
                  value={nomeSimulado}
                  onChange={(e) => setNomeSimulado(e.target.value)}
                ></Input>
                <Select
                  value=""
                  onChange={(e) => {
                    handleSelectQuestion(
                      e.target.value,
                      e.target.options[e.target.selectedIndex].text
                    );
                  }}
                >
                  <option value="" disabled>
                    Selecione uma questão
                  </option>
                  {questionsList?.map((question) => (
                    <option
                      key={question.id}
                      value={question.id}
                      dangerouslySetInnerHTML={{
                        __html: question.question?.question,
                      }}
                    ></option>
                  ))}
                </Select>
                {selectedQuestions.map((question) => (
                  <Box key={question.id} display="flex" alignItems="center">
                    <Text
                      mr={2}
                      dangerouslySetInnerHTML={{
                        __html: question.question,
                      }}
                    ></Text>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleRemoveQuestion(question.id)}
                    >
                      x
                    </Button>
                  </Box>
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
                Adicionar
              </Button>
              <Button onClick={onClose}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AdicionarSimuladoModal;
