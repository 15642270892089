import { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api";
import Navbar from "../../Components/Navbar";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  useToast,
  useDisclosure,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  SimpleGrid,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";
import { MateriaContext } from "../../contexts/Materia";
import { BookContext } from "../../contexts/Book";
const UserProfilePage = () => {
  const { getUserApi, updateUserApi, getUserResultsApi } =
    useContext(UserContext);
  const fileInputRef = useRef(null);
  const { listMateriaApi } = useContext(MateriaContext);
  const { createBookApi, listBookApi, deleteBookApi } = useContext(BookContext);
  const toast = useToast();
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const [userID, setUserID] = useState("");
  const [newBook, setNewBook] = useState("");
  const [materias, setMaterias] = useState();
  const [user, setUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [materyID, setMateryID] = useState();
  const [books, setBooks] = useState();
  const [profileImage, setProfileImage] = useState("");
  const [totalResults, setResults] = useState();

  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddNotebookModalOpen,
    onOpen: onAddNotebookModalOpen,
    onClose: onAddNotebookModalClose,
  } = useDisclosure();

  // Cadernos
  const [notebookss, setNotebooks] = useState([]);
  const [newNotebook, setNewNotebook] = useState({ subject: "", content: "" });

  const handleSelectBook = (id) => {
    localStorage.setItem("book_id", id);
    navigate(`/book/${id}`);
  };
  const handleSubjectChange = (event) => {
    setNewNotebook({ ...newNotebook, subject: event.target.value });
  };

  const handleContentChange = (content) => {
    setNewNotebook({ ...newNotebook, content });
  };

  const addNotebook = () => {
    // Lógica para adicionar o caderno à lista de cadernos
    setNotebooks([...books, newNotebook]);
    // Lógica para enviar o caderno para a API e persistir no banco de dados
    // ...
    // Limpar os campos do formulário do novo caderno
    setNewNotebook({ subject: "", content: "" });
    // Fechar o modal
    onAddNotebookModalClose();
  };

  const handleNameChange = (event) => {
    setEditedUser({ ...editedUser, name: event.target.value });
  };

  const handleEmailChange = (event) => {
    setEditedUser({ ...editedUser, email: event.target.value });
  };

  const updateUser = () => {
    // Lógica para atualizar o usuário com as informações editadas
    // ...
    onEditModalClose();
  };
  const handleCpfChange = (event) => {
    setEditedUser({ ...editedUser, cpf: event.target.value });
  };

  const handlePasswordChange = (event) => {
    setEditedUser({ ...editedUser, password: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    setEditedUser({ ...editedUser, confirmPassword: event.target.value });
  };

  const getBooks = async () => {
    try {
      const booksData = await listBookApi(userID);
      console.log(booksData.data?.[0]);
      setBooks(booksData.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBookUser = async (id) => {
    try {
      const deleteBook = await deleteBookApi(id);
      console.log(deleteBook.data);
      if (deleteBook.status === 200) {
        toast({
          title: "Caderno deletado com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getBooks();
  };
  const getUser = async () => {
    try {
      const userData = await getUserApi(userID);
      setUser(userData.data?.[0]);
      setProfileImage(userData.data?.[0]?.avatar);
      console.log(userData.data?.[0]?.avatar);
    } catch (error) {
      console.log(error);
      window.location.pathname = "/login";
    }
  };

  // Função para lidar com a seleção de uma imagem de perfil
  // Função para lidar com a seleção de uma imagem de perfil
  const handleImageChange = (event) => {
    const image = event.target.files[0];
    setProfileImage(image);

    const formData = new FormData();
    formData.append("avatar", image);
    // Obtenha o token de autorização do localStorage
    const token = localStorage.getItem("token");

    api
      .post("upload-avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.message); // Mensagem de sucesso
        getUser();
        fileInputRef.current.value = null;
      })
      .catch((error) => {
        console.error("Erro ao fazer upload: " + error.message);
      });
  };

  const getUserResults = async (ID) => {
    try {
      const results = await getUserResultsApi(userID);
      console.log(results.data?.[0]);
      setResults(results.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfoID"));
    if (userInfo) {
      setUserID(userInfo);
    }

    if (userID) {
      getUser(userID);
      getUserResults(userInfo);
    }
    getBooks();
  }, [userID]);

  return (
    <>
      <Navbar />
      <>
        <Box
          p={8}
          ml={{ base: 0, md: 60 }}
          bgColor={"#f0f8ff"}
          minH={"100vh"}
          mt={"-35px"}
        >
          <Box p={8} bg="white" borderRadius="md" boxShadow="md">
            <Heading as="h1" size="xl" mb={4}>
              Perfil do Usuário
            </Heading>
            <Box mb={5}>
              <Image
                borderRadius="full"
                boxSize="150px"
                src={
                  profileImage === null
                    ? "https://via.placeholder.com/150"
                    : "https://api.colegionaval.com.br/public/" + profileImage
                }
                alt={user?.name}
                mb={2}
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
              />
            </Box>
            <Text>
              <strong>ID:</strong> {user?.id}
            </Text>
            <Text>
              <strong>Nome:</strong> {user?.name}
            </Text>
            <Text>
              <strong>E-mail:</strong> {user?.email}
            </Text>
            <Text>
              <strong>Tipo:</strong>{" "}
              {user?.role_id === 2
                ? "Professor"
                : user?.role_id === 3
                ? "Aluno"
                : "Administrador"}
            </Text>

            <Button mt={4} colorScheme="teal" onClick={onEditModalOpen}>
              Editar Usuário
            </Button>
          </Box>
          {user?.role_id === 3 && (
            <>
              <Box mt={5} p={8} bg="white" borderRadius="md" boxShadow="md">
                <Text fontWeight="bold" fontSize="18px">
                  Anotações do aluno
                </Text>
                <Box>
                  <Table>
                    <Thead fontWeight={"bold"}>
                      <Td>Questão</Td>
                      <Td>Conteúdo caderno</Td>
                      <Td>Ação</Td>
                    </Thead>
                    {books?.map((index) => (
                      <Tr>
                        <Td
                          dangerouslySetInnerHTML={{
                            __html: index.question?.question,
                          }}
                        ></Td>
                        <Td
                          dangerouslySetInnerHTML={{
                            __html: index.book.substring(0, 50),
                          }}
                        ></Td>

                        <Td>
                          <Button onClick={() => handleSelectBook(index.id)}>
                            {" "}
                            Acessar caderno{" "}
                          </Button>
                          <Button
                            ml={5}
                            colorScheme="red"
                            onClick={() => deleteBookUser(index.id)}
                          >
                            Excluir
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Table>
                </Box>
              </Box>

              {totalResults != null && (
                <Box mt={5} p={8} bg="white" borderRadius="md" boxShadow="md">
                  <Text fontWeight="bold" fontSize="18px">
                    Resultados de Simulados
                  </Text>
                  <Box mt={5}>
                    <Text>Total de Respostas</Text>
                    <Box mt={5}>
                      <SimpleGrid columns={2}>
                        <Box textAlign={"center"}>
                          <Text fontWeight={"bold"}>
                            Total respostas erradas
                          </Text>
                          <Text fontSize={"24px"}>
                            {totalResults.totalAnswers?.qty_wrong}
                          </Text>
                        </Box>
                        <Box textAlign={"center"}>
                          <Text fontWeight={"bold"}>
                            Total respostas certas
                          </Text>
                          <Text fontSize={"24px"}>
                            {totalResults.totalAnswers?.qty_right}
                          </Text>
                        </Box>
                      </SimpleGrid>
                      <Table mt={5}>
                        <Tr>
                          <Td textAlign={"center"}>Id Simulado</Td>
                          <Td textAlign={"center"}>
                            Quantidade de respostas certas
                          </Td>
                          <Td textAlign={"center"}>
                            Quantidade de respostas erradas
                          </Td>
                        </Tr>
                        <Tbody>
                          {totalResults?.simulatorsResults?.map((index) => (
                            <>
                              <Tr>
                                <Td textAlign={"center"}>
                                  {index.simulator_id}
                                  {console.log(index)}
                                </Td>
                                <Td textAlign={"center"}>{index.qty_right}</Td>
                                <Td textAlign={"center"}>{index.qty_wrong}</Td>
                              </Tr>
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>

        <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={2}>Name:</Text>
              <Input
                type="text"
                value={editedUser.name || user?.name}
                onChange={handleNameChange}
              />
              <Text mt={4} mb={2}>
                Email:
              </Text>
              <Input
                type="email"
                value={editedUser.email || user?.email}
                onChange={handleEmailChange}
              />
              <Text mt={4} mb={2}>
                CPF:
              </Text>
              <Input
                mask="999.999.999-99"
                value={editedUser.cpf || ""}
                onChange={handleCpfChange}
              ></Input>
              <Text mt={4} mb={2}>
                Password:
              </Text>
              <Input
                type="password"
                value={editedUser.password || ""}
                onChange={handlePasswordChange}
              />
              <Text mt={4} mb={2}>
                Confirm Password:
              </Text>
              <Input
                type="password"
                value={editedUser.confirmPassword || ""}
                onChange={handleConfirmPasswordChange}
              />
              {/* Outros campos para edição */}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={updateUser}>
                Update
              </Button>
              <Button onClick={onEditModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isAddNotebookModalOpen}
          onClose={onAddNotebookModalClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create New Notebook</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={2}>Subject:</Text>
              <Input
                type="text"
                value={newNotebook.subject}
                onChange={handleSubjectChange}
              />
              <Text mt={4} mb={2}>
                Content:
              </Text>
              {/* Implemente aqui o componente de editor desejado (por exemplo, CKEditor, Draft.js, etc.) */}
              {/* <Editor value={newNotebook.content} onChange={handleContentChange} /> */}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={addNotebook}>
                Add Notebook
              </Button>
              <Button onClick={onAddNotebookModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default UserProfilePage;
