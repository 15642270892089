import React, { useState, useEffect, useContext } from "react";
import api from "../services/api";
import {
  Box,
  Link,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Logo from "../images/logo.png";
import { UserContext } from "../contexts/User";
import { AuthContext } from "../contexts/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
const MenuPrincipal = ({ isUserLoggedIn }) => {
  const [userID, setUserID] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { getUserApi, updateUserApi, getUserAvatarApi, userApi } =
    useContext(UserContext);
  const { logout } = useContext(AuthContext);
  const [logged, setLogged] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [user, setUser] = useState("");
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleLogout = () => {
    logout();
    // Recarregue a página
    window.location.reload();
  };

  function userLogged() {
    const user = JSON.parse(localStorage.getItem("user")); // Converte o JSON para objeto
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (user) {
      api
        .get(`/user/${user.id}/status`, { headers })
        .then((response) => {
          setLogged(true);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            setLogged(false);
          } else {
            setLogged(true);
          }
        });
    }
  }

  const getUser = async () => {
    try {
      const userData = await getUserApi(userID.id);
      setUser(userData.data?.[0]);
      console.log("menu" + userData.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
    userLogged();
  }, [isUserLoggedIn]);

  console.log(userApi?.[0]?.avatar);
  return (
    <Box
      bg="#050f40"
      display="flex"
      alignItems="center"
      maxWidth={"100vw"}
      justifyContent="space-between"
    >
      <Box p={4} ml={"100px"}>
        <a
          href="https://www.colegionaval.com.br/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={Logo} maxWidth="50%" />
        </a>
      </Box>
      <Box
        color="white"
        ml="55px"
        mr="55px"
        display={{ base: "none", md: "flex" }}
        pt={2}
        fontSize={"14px"}
      >
        <Box mt={2}>
          <Link href="https://www.colegionaval.com.br/" color="white">
            Voltar ao Site
          </Link>
          <Link ml={5} href="/">
            Questões
          </Link>
          <Link ml={5} href="/selecionar/simulado">
            Fazer um simulado online
          </Link>
        </Box>

        {user ? (
          <Menu>
            <MenuButton ml={5} mr={5}>
              <Box display="flex" alignItems="center" fontSize={"10px"}>
                {user.avatar != null ? (
                  <Image
                    src={
                      "https://api.colegionaval.com.br/public/" +
                      userApi?.[0]?.avatar
                    }
                    alt="User Avatar"
                    w={10}
                    h={10}
                    borderRadius="full"
                    mr={2}
                  />
                ) : (
                  <Image
                    src={
                      "https://cdn.pixabay.com/photo/2017/02/23/13/05/avatar-2092113_1280.png"
                    }
                    alt="User Avatar"
                    w={10}
                    h={10}
                    borderRadius="full"
                    mr={2}
                  />
                )}

                {user.name}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{ marginLeft: "8px" }}
                />
              </Box>
            </MenuButton>
            <MenuList>
              {user.role_id === 3 ? (
                <>
                  <MenuItem
                    as={Link}
                    href={`/home/${user.name}`}
                    className="color-menu"
                    color={"#050F40"}
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    className="color-menu"
                    color={"#050F40"}
                  >
                    Sair
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem
                    as={Link}
                    href="/home/admin"
                    className="color-menu"
                    color={"#050F40"}
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    className="color-menu"
                    color={"#050F40"}
                  >
                    Sair
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
        ) : (
          <Link mt={2} ml={5} href="/login">
            Fazer Login
          </Link>
        )}
      </Box>
      <IconButton
        icon={<HamburgerIcon />}
        aria-label="Open Menu"
        display={{ base: "flex", md: "none" }}
        onClick={handleDrawerOpen}
        m={2}
      />
      <Drawer
        placement="right"
        onClose={handleDrawerClose}
        isOpen={isDrawerOpen}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <Link
                href="https://colegio-naval.homologacaovip.com.br/"
                color="black"
              >
                Voltar ao Site
              </Link>
              <Link href="/">Questionário</Link>
              <Link href="/selecionar/simulado">Fazer um simulado online</Link>
              {logged === null ? (
                <Link href="/login">Fazer Login</Link>
              ) : (
                <Link href="/home/admin">Dashboard Aluno</Link>
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MenuPrincipal;
