import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import { SimuladoContext } from "../../contexts/Simulado";
import AdicionarSimuladoModal from "../../Components/AddSimulado";

const Simulados = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { listSimuladoApi, deleteSimuladoApi } = useContext(SimuladoContext);
  const [simulados, setSimulados] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function getSimulados() {
    try {
      const simuladosData = await listSimuladoApi();
      console.log(simuladosData);
      setSimulados(simuladosData.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectSimulado = (simulado, id) => {
    localStorage.setItem("simulado_id", id);
    navigate(`/simulado/${simulado}`);
  };

  const deleteSimulado = async (id) => {
    try {
      const deleteSimulado = await deleteSimuladoApi(id);
      console.log(deleteSimulado.data);
      if (deleteSimulado.status === 200) {
        toast({
          title: "Simulado deletado com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        getSimulados();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    getSimulados();
  }, []);

  return (
    <>
      <Navbar />
      {simulados && (
        <Box p={8} ml={{ base: 0, md: 60 }}>
          <Flex justifyContent={"space-between"}>
            <Heading as="h1" size="xl" mb={4}>
              Lista de Simulados
            </Heading>
            <Button colorScheme="teal" mb={4} onClick={onOpen}>
              Criar Simulado
            </Button>
          </Flex>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Título do Simulado</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {simulados.map((simulado) => (
                <Tr key={simulado.id}>
                  <Td>{simulado.title}</Td>
                  <Td>
                    <Button
                      colorScheme="teal"
                      onClick={() =>
                        handleSelectSimulado(simulado.title, simulado.id)
                      }
                    >
                      Acessar Simulado
                    </Button>
                    <Button
                      ml={5}
                      colorScheme="red"
                      onClick={() => deleteSimulado(simulado.id)}
                    >
                      Excluir
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      <AdicionarSimuladoModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          getSimulados();
        }}
      />
    </>
  );
};

export default Simulados;
