import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import { UserContext } from "../../contexts/User";
import UserRegistrationModal from "../../Components/createUserModal";

const Users = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { listUsersApi, deleteUserApi } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    getUsers();
  }, []);

  const handleSelectUser = (user, id) => {
    localStorage.setItem("userInfoID", id);
    navigate(`/user/${user}`);
  };

  const getUsers = async () => {
    try {
      const usersData = await listUsersApi();
      setUsers(usersData.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const deleteUser = await deleteUserApi(userId);
      console.log(deleteUser.data);
      if (deleteUser.status === 200) {
        toast({
          title: "Usuário Removido",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getUsers();
  };

  return (
    <>
      <Navbar />
      <Box
        p={8}
        ml={{ base: 0, md: 60 }}
        bgColor={"#f0f8ff"}
        minH={"100vh"}
        mt={"-35px"}
      >
        <Flex justifyContent={"space-between"}>
          <Heading as="h1" size="xl" mb={4}>
            Lista de Usuários
          </Heading>
          <Button colorScheme="teal" mb={4} onClick={onOpen}>
            Adicionar Usuário
          </Button>
        </Flex>
        <Box overflowX="auto">
          <Table variant="striped" overflow={"auto"}>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Nome</Th>
                <Th>E-mail</Th>
                <Th>Tipo</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users?.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.id}</Td>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>
                    {user.role_id === 1 && "Master"}
                    {user.role_id === 2 && "Professor"}
                    {user.role_id === 3 && "Aluno"}
                  </Td>
                  <Td>
                    <Button
                      colorScheme="teal"
                      size={"sm"}
                      onClick={() => handleSelectUser(user.name, user.id)}
                    >
                      Ver perfil
                    </Button>
                    <Button
                      ml={5}
                      size={"sm"}
                      colorScheme="red"
                      onClick={() => deleteUser(user.id)}
                    >
                      Excluir
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <UserRegistrationModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          getUsers();
        }}
      />
    </>
  );
};

export default Users;
