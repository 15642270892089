import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import { ChakraProvider } from '@chakra-ui/react'
import { AppProvider } from "./contexts";

function App() {
  return (
    <>
      <ChakraProvider>
        <BrowserRouter>
          <AppProvider>
            <Router />
          </AppProvider>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
};


export default App;