import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost/api-colegio-naval/public/api",
  //baseURL: "https://api-colegio-naval.gianfrancopedrol.com.br/public/api",
  baseURL: "https://api.colegionaval.com.br/public/api",
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("token");
  //  alert(token);
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  return config;
});

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error?.response?.status === 401) {
      // window.location.pathname = "/";
    }

    return Promise.reject(error);
  }
);

export default api;
