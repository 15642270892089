import { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Select,
  useToast,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import { UserContext } from "../../contexts/User";
import { MateriaContext } from "../../contexts/Materia";
import { BookContext } from "../../contexts/Book";
import JoditEditor, { Jodit } from "../../Components/Editor";
import MenuPrincipal from "../../Components/Menu";

const HomeAluno = () => {
  const { getUserApi, updateUserApi, getUserResultsApi, userApi, setUserApi } =
    useContext(UserContext);
  const fileInputRef = useRef(null);
  const { listMateriaApi } = useContext(MateriaContext);
  const { createBookApi, listBookApi, deleteBookApi } = useContext(BookContext);
  const toast = useToast();
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const [userID, setUserID] = useState("");
  const [newBook, setNewBook] = useState("");
  const [materias, setMaterias] = useState();
  const [user, setUser] = useState(null);
  const [editedUser, setEditedUser] = useState({});
  const [materyID, setMateryID] = useState();
  const [books, setBooks] = useState();
  const [profileImage, setProfileImage] = useState("");
  const [totalResults, setResults] = useState();

  const handleSelectBook = (id) => {
    localStorage.setItem("book_id", id);
    navigate(`/book/${id}`);
  };

  // Função para lidar com a seleção de uma imagem de perfil
  // Função para lidar com a seleção de uma imagem de perfil
  const handleImageChange = (event) => {
    const image = event.target.files[0];
    setProfileImage(image);

    const formData = new FormData();
    formData.append("avatar", image);
    // Obtenha o token de autorização do localStorage
    const token = localStorage.getItem("token");

    api
      .post("upload-avatar", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUser((prevUser) => ({
          ...prevUser,
          avatar: response.data?.[0]?.avatar,
        }));
        console.log(response.data?.[0]?.avatar);
        getUser();
        fileInputRef.current.value = null;
      })
      .catch((error) => {
        console.error("Erro ao fazer upload: " + error.message);
      });
  };

  async function getMateriasList() {
    try {
      const materiasInfoApi = await listMateriaApi();
      console.log(materiasInfoApi.data?.[0]);
      setMaterias(materiasInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const {
    isOpen: isAddNotebookModalOpen,
    onOpen: onAddNotebookModalOpen,
    onClose: onAddNotebookModalClose,
  } = useDisclosure();

  const [newNotebook, setNewNotebook] = useState({ subject: "", content: "" });

  const addNotebook = async () => {
    const data = {
      user_id: user.id,
      matery_id: materyID,
      book: newBook,
    };
    try {
      const createBook = await createBookApi(data);
      console.log(createBook);

      if (createBook.status === 200) {
        toast({
          title: "Caderno criado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
      // Exibir mensagem de erro ao usuário, se necessário
    }

    setNewBook("");
    getBooks();
    onAddNotebookModalClose();
  };

  const deleteBookUser = async (id) => {
    try {
      const deleteBook = await deleteBookApi(id);
      console.log(deleteBook.data);
      if (deleteBook.status === 200) {
        toast({
          title: "Caderno deletado com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getBooks();
  };

  const handleNameChange = (event) => {
    setEditedUser({ ...editedUser, name: event.target.value });
  };

  const handleEmailChange = (event) => {
    setEditedUser({ ...editedUser, email: event.target.value });
  };

  const updateUser = () => {
    // Lógica para atualizar o usuário com as informações editadas
    // ...
    onEditModalClose();
  };
  const handleCpfChange = (event) => {
    setEditedUser({ ...editedUser, cpf: event.target.value });
  };

  const handlePasswordChange = (event) => {
    setEditedUser({ ...editedUser, password: event.target.value });
  };

  const handleConfirmPasswordChange = (event) => {
    setEditedUser({ ...editedUser, confirmPassword: event.target.value });
  };

  const getUser = async () => {
    try {
      const userData = await getUserApi(userID);
      setUser(userData.data?.[0]);
      setProfileImage(userData.data?.[0]?.avatar);
    } catch (error) {
      console.log(error);
    }
  };

  const getBooks = async () => {
    try {
      const booksData = await listBookApi(userID);
      console.log(booksData.data?.[0]);
      setBooks(booksData.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserResults = async () => {
    try {
      const results = await getUserResultsApi(userID);
      console.log(results.data?.[0]);
      setResults(results.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectMatery = (id) => {
    setMateryID(id);
  };

  const handleChangeNewBook = (value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setNewBook(value);
    }, 300); // Atraso de 300 milissegundos (ajuste conforme necessário)
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user"));
    if (userInfo) {
      setUserID(userInfo.id);
    }

    if (userID) {
      getUser(user);
      getMateriasList();
    }
    getBooks();
    getUserResults();
  }, [userID]);

  if (!user) {
    return <p>Loading...</p>; // Mostrar um indicador de carregamento enquanto busca os dados do usuário
  }

  return (
    <>
      <MenuPrincipal />
      <>
        <Box
          p={8}
          ml={user.role_id != 3 ? { base: 0, md: 60 } : { base: 0, md: 0 }}
        >
          <Box p={8} bg="white" borderRadius="md" boxShadow="md">
            <Heading as="h1" size="xl" mb={4}>
              Perfil do Usuário
            </Heading>
            <Box mb={5}>
              <Image
                borderRadius="full"
                boxSize="150px"
                src={
                  profileImage === null
                    ? "https://via.placeholder.com/150"
                    : "https://api.colegionaval.com.br/public/" + profileImage
                }
                alt={user.name}
                mb={2}
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
              />
            </Box>
            <Text>
              <strong>ID:</strong> {user?.id}
            </Text>
            <Text>
              <strong>Nome:</strong> {user?.name}
            </Text>
            <Text>
              <strong>E-mail:</strong> {user?.email}
            </Text>
            <Text>
              <strong>Tipo:</strong>{" "}
              {user?.role_id === 2
                ? "Professor"
                : user?.role_id === 3
                ? "Aluno"
                : ""}
            </Text>

            <Button mt={4} colorScheme="teal" onClick={onEditModalOpen}>
              Editar
            </Button>
          </Box>
          {user?.role_id === 3 && (
            <>
              <Box mt={5} p={8} bg="white" borderRadius="md" boxShadow="md">
                <Text fontWeight="bold" fontSize="18px">
                  Minhas Anotações
                </Text>
                <Box>
                  <Table>
                    <Thead fontWeight={"bold"}>
                      <Td>Questão</Td>
                      <Td>Conteúdo caderno</Td>
                      <Td>Ação</Td>
                    </Thead>
                    {books?.map((index) => (
                      <Tr>
                        <Td
                          dangerouslySetInnerHTML={{
                            __html: index.question?.question,
                          }}
                        ></Td>
                        <Td
                          dangerouslySetInnerHTML={{
                            __html: index.book.substring(0, 50),
                          }}
                        ></Td>

                        <Td>
                          <Button onClick={() => handleSelectBook(index.id)}>
                            {" "}
                            Acessar caderno{" "}
                          </Button>
                          <Button
                            ml={5}
                            colorScheme="red"
                            onClick={() => deleteBookUser(index.id)}
                          >
                            Excluir
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Table>
                </Box>
              </Box>

              {totalResults != null && (
                <Box mt={5} p={8} bg="white" borderRadius="md" boxShadow="md">
                  <Text fontWeight="bold" fontSize="18px">
                    Resultados de Simulados
                  </Text>
                  <Box mt={5}>
                    <Text>Total de Respostas</Text>
                    <Box mt={5}>
                      <SimpleGrid columns={2}>
                        <Box textAlign={"center"}>
                          <Text fontWeight={"bold"}>
                            Total respostas erradas
                          </Text>
                          <Text fontSize={"24px"}>
                            {totalResults?.totalAnswers?.qty_wrong}
                          </Text>
                        </Box>
                        <Box textAlign={"center"}>
                          <Text fontWeight={"bold"}>
                            Total respostas certas
                          </Text>
                          <Text fontSize={"24px"}>
                            {totalResults?.totalAnswers?.qty_right}
                          </Text>
                        </Box>
                      </SimpleGrid>
                      <Table mt={5}>
                        <Tr>
                          <Td textAlign={"center"}>Id Simulado</Td>
                          <Td textAlign={"center"}>
                            Quantidade de respostas certas
                          </Td>
                          <Td textAlign={"center"}>
                            Quantidade de respostas erradas
                          </Td>
                        </Tr>
                        <Tbody>
                          {totalResults?.simulatorsResults?.map((index) => (
                            <>
                              <Tr>
                                <Td textAlign={"center"}>
                                  {index.simulator_id}
                                  {console.log(index)}
                                </Td>
                                <Td textAlign={"center"}>{index.qty_right}</Td>
                                <Td textAlign={"center"}>{index.qty_wrong}</Td>
                              </Tr>
                            </>
                          ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>

        <Modal isOpen={isEditModalOpen} onClose={onEditModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Atualizar usuário</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={2}>Name:</Text>
              <Input
                type="text"
                value={editedUser.name || user.name}
                onChange={handleNameChange}
              />
              <Text mt={4} mb={2}>
                Email:
              </Text>
              <Input
                type="email"
                value={editedUser.email || user.email}
                onChange={handleEmailChange}
              />
              <Text mt={4} mb={2}>
                CPF:
              </Text>
              <Input
                mask="999.999.999-99"
                value={editedUser.cpf || ""}
                onChange={handleCpfChange}
              ></Input>
              <Text mt={4} mb={2}>
                Password:
              </Text>
              <Input
                type="password"
                value={editedUser.password || ""}
                onChange={handlePasswordChange}
              />
              <Text mt={4} mb={2}>
                Confirm Password:
              </Text>
              <Input
                type="password"
                value={editedUser.confirmPassword || ""}
                onChange={handleConfirmPasswordChange}
              />
              {/* Outros campos para edição */}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={updateUser}>
                Atualizar
              </Button>
              <Button onClick={onEditModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={isAddNotebookModalOpen}
          onClose={onAddNotebookModalClose}
        >
          <ModalOverlay />
          <ModalContent width={"900px"} maxW={"90%"}>
            <ModalHeader>Novas Anotações</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={2}>Matéria</Text>
              <Select
                value={materyID ? materyID : ""}
                onChange={(e) => {
                  handleSelectMatery(e.target.value);
                }}
              >
                <option value="">Selecione uma questão</option>
                {materias?.map((index) => (
                  <option key={index.id} value={index.id}>
                    {index.matery}
                  </option>
                ))}
              </Select>
              <VStack spacing={4} align="stretch" mt={5}>
                <Text>Anotações:</Text>
                <JoditEditor
                  value={newBook ?? " "}
                  onChange={(value) => handleChangeNewBook(value)}
                  spellCheck={false}
                  cursorOffSet={50}
                />
              </VStack>
              {/* Implemente aqui o componente de editor desejado (por exemplo, CKEditor, Draft.js, etc.) */}
              {/* <Editor value={newNotebook.content} onChange={handleContentChange} /> */}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={addNotebook}>
                Adicionar
              </Button>
              <Button onClick={onAddNotebookModalClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default HomeAluno;
