import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { AuthContext } from "./contexts/Auth";
import Login from "./Pages/Login/Login";
import HomeAluno from "./Pages/Home/HomeAluno";
import HomeAdmin from "./Pages/Home/HomeAdmin";
import Materias from "./Pages/Materias/Materias";
import MateriaPage from "./Pages/Materias/Materia";
import Simulados from "./Pages/Simulado/Simulados";
import SimuladoPage from "./Pages/Simulado/Simulado";
import Users from "./Pages/Users/Users";
import UserProfilePage from "./Pages/Users/User";
import BookStudent from "./Pages/Book/Book";
import QuestionsPage from "./Pages/Questions/Questions";
import SelectSimulado from "./Pages/Simulado/SelectSimulado";
import SimuladorPage from "./Pages/Simulado/SimuladorOnlinePage";
import RegisterNewUser from "./Pages/Users/RegisterUser";
function Router() {
  const Private = ({ children }) => {
    const { user, authenticated } = useContext(AuthContext);
    const token = localStorage.getItem("token");

    if (token === null || user === false) {
      return <Navigate to="/login" />;
    }

    return children;
  };
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/" element={<QuestionsPage />} />
      <Route exact path="/cadastro" element={<RegisterNewUser />} />
      <Route exact path="/selecionar/simulado" element={<SelectSimulado />} />
      <Route exact path="/simulado/online" element={<SimuladorPage />} />

      <Route
        path="/home/admin"
        element={
          <Private>
            <HomeAdmin />
          </Private>
        }
      />
      <Route
        path="/home/:slug"
        element={
          <Private>
            <HomeAluno />
          </Private>
        }
      />
      <Route
        path="/materias"
        element={
          <Private>
            <Materias />
          </Private>
        }
      />
      <Route
        path="/materia/:slug"
        element={
          <Private>
            <MateriaPage />
          </Private>
        }
      />
      <Route
        path="/simulados"
        element={
          <Private>
            <Simulados />
          </Private>
        }
      />
      <Route
        path="/simulado/:slug"
        element={
          <Private>
            <SimuladoPage />
          </Private>
        }
      />
      <Route
        path="/users"
        element={
          <Private>
            <Users />
          </Private>
        }
      />
      <Route
        path="/user/:slug"
        element={
          <Private>
            <UserProfilePage />
          </Private>
        }
      />

      <Route
        path="/book/:id"
        element={
          <Private>
            <BookStudent />
          </Private>
        }
      />
    </Routes>
  );
}

export default Router;
