import React from "react";
import { AuthProvider } from "./Auth";
import { UserProvider } from "./User";
import { MateriaProvider } from "./Materia";
import { SimuladoProvider } from "./Simulado";
import { BookProvider } from "./Book";
export const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <MateriaProvider>
          <SimuladoProvider>
            <BookProvider>{children}</BookProvider>
          </SimuladoProvider>
        </MateriaProvider>
      </UserProvider>
    </AuthProvider>
  );
};
