import { useState, useContext, useEffect } from "react";
import api from "../../services/api";
import MenuPrincipal from "../../Components/Menu";
import { Link } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  Select,
  Flex,
  Heading,
  Image,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { MateriaContext } from "../../contexts/Materia";
import QuestionList from "../../Components/listQuestions";
import QuestionMateryList from "../../Components/listQuestionsMatery";
import Logo from "../../images/logo.png";
import LoginModal from "../../Components/ModalLogin";
import EsqueceuSenhaModal from "../../Components/EsqueceuSenha";

const QuestionsPage = () => {
  const toast = useToast();
  const {
    listQuestoesApi,
    listMateriaApi,
    listMateriaQuestoesApi,
    DetailMateriaApi,
  } = useContext(MateriaContext);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const [materias, setMaterias] = useState([]);
  const [logged, setLogged] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState();
  const [nameMatery, setMateryName] = useState();
  const [emailForgot, setEmailForgot] = useState("");
  const [showEsqueceuSenhaModal, setShowEsqueceuSenhaModal] = useState(false);

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };
    try {
      const response = await api.post("auth/login", data);

      const loggedUser = response.data.user;
      const token = response.data.token;

      localStorage.setItem("user", JSON.stringify(loggedUser));
      localStorage.setItem("token", token);

      setUser(loggedUser);
      setIsLoggedIn(true);
      if (response.status === 200) {
        setIsUserLoggedIn(true);
        setIsLoggedIn(true);

        setShowLoginModal(false); // Feche o modal de login após o login bem-sucedido
      }
      window.location.reload();
      return response;
    } catch (error) {
      JSON.stringify(error);
      console.log(error.data);
      toast({
        title: "Verifique o email e senha",
        status: "error",
        isClosable: true,
      });
      return error;
    }
  };

  async function getMateriaList() {
    try {
      const responseMateria = await listMateriaApi();
      // Atualiza a lista de matérias com a resposta da API
      setMaterias(responseMateria?.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSelectMateria(id, matery) {
    if (id === "") {
      setSelectedMateria(null);
    } else {
      localStorage.setItem("matery_id", id);
      setSelectedMateria(id);
      setMateryName(matery.matery);
    }
  }

  function userLogged() {
    const user = JSON.parse(localStorage.getItem("user")); // Converte o JSON para objeto
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (user === null) {
      setShowLoginModal(true); // Se o usuário não estiver logado, exiba o modal de login
    } else {
      api
        .get(`/user/${user.id}/status`, { headers })
        .then((response) => {
          setLogged(true);
        })
        .catch((error) => {
          setShowLoginModal(true);
          if (error.response && error.response.status === 401) {
            setShowLoginModal(true);
            // Exiba o modal de login se ocorrer um erro de autenticação
          } else {
            setLogged(true);
          }
        });
    }
  }

  const handleEsqueceuSenha = () => {
    setShowLoginModal(false);
    setShowEsqueceuSenhaModal(true);
  };

  useEffect(() => {
    userLogged();
    getMateriaList();
  }, [selectedMateria]);

  return (
    <>
      <MenuPrincipal isUserLoggedIn={isUserLoggedIn} />

      <Box
        bg="#050f40"
        h="80px"
        display="flex"
        justifyContent={"center"}
        color={"white"}
        textAlign={"center"}
      >
        <Heading>Área de Estudos</Heading>
      </Box>
      <Flex justifyContent={"center"} mt={5}>
        <Box borderRadius="md" p={4} display={"inline-flex"} width={"700px"}>
          {materias && materias.length > 0 && (
            <Select
              placeholder="Selecione uma matéria"
              value={selectedMateria}
              onChange={(e) => {
                const selectedId = e.target.value;
                const selectedMateria = materias.find(
                  (materia) => materia.id == selectedId
                );

                if (selectedMateria) {
                  handleSelectMateria(selectedId, selectedMateria);
                } else {
                  console.error(`Matéria com ID ${selectedId} não encontrada.`);
                }
              }}
              mb={4}
            >
              {materias?.map((materia) => (
                <option key={materia.id} value={materia.id}>
                  {materia.matery}
                </option>
              ))}
            </Select>
          )}
        </Box>
      </Flex>
      <Box>
        {selectedMateria == null ? (
          ""
        ) : (
          <QuestionMateryList matery={nameMatery} />
        )}
      </Box>

      {showLoginModal && (
        <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Login</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Button
                variant="link"
                color="blue.500"
                onClick={handleEsqueceuSenha}
              >
                Esqueceu a Senha?
              </Button>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleLogin}>
                Login
              </Button>
              <Button variant="ghost" onClick={() => setShowLoginModal(false)}>
                Cancelar
              </Button>
            </ModalFooter>
            <Box maxW={"350px"}>
              <Stack
                ml={5}
                pb={2}
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Text>Ainda não tem Cadastro?</Text>

                <Link to={"/cadastro"}>
                  <Text color={"#FAB929"} fontWeight={"bold"}>
                    Cadastre-se
                  </Text>
                </Link>
              </Stack>
            </Box>
          </ModalContent>
        </Modal>
      )}

      <EsqueceuSenhaModal
        isOpen={showEsqueceuSenhaModal}
        onClose={() => setShowEsqueceuSenhaModal(false)}
      />
    </>
  );
};

export default QuestionsPage;
