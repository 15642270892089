import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { AuthContext } from "../../contexts/Auth";
import MenuPrincipal from "../../Components/Menu";
import BGLogin from "../../images/bg-login.png";

const Login = () => {
  const { login, loading, forgotPasswordApi } = useContext(AuthContext);
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmitForgot = async () => {
    const data = {
      email: emailForgot,
    };

    try {
      const response = await forgotPasswordApi(data);
      toast({
        title: "E-mail enviado!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      handleClose();
    } catch (error) {
      console.log(error);
      toast.info("Erro ao se conectar com o servidor!", {
        autoClose: 5000,
      });
    }
  };

  return (
    <>
      <MenuPrincipal />
      <Box
        mt={"-28px"}
        bgImage={BGLogin}
        width={"99vw"}
        height={"350px"}
        backgroundSize={"cover"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Box color={"#FAB929"} fontSize={"28px"} textAlign={"center"}>
            <Text>Área Aluno</Text>
          </Box>
          <Box color={"white"} fontSize={"48px"} fontWeight={"medium"}>
            <Text>COLÉGIO NAVAL</Text>
          </Box>
        </Box>
      </Box>
      <Flex minH={"100vh"} justifyContent={"center"} bg="#050f40">
        <Stack spacing={8} mx={"auto"} py={2} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>
              <img alt="" srcset="" />
            </Heading>
          </Stack>
          <Box
            opacity={1}
            color={"white"}
            as="form"
            onSubmit={handleSubmit}
            rounded={"lg"}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <Input
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl id="password">
                <Input
                  placeholder="Senha"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Stack spacing={10} color={"white"}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Link onClick={handleOpen} color={"blue.400"}>
                    Esqueceu a senha?
                  </Link>
                </Stack>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Text>Ainda não tem Cadastro?</Text>

                  <Link to={"/cadastro"}>
                    <Text color={"#FAB929"} fontWeight={"bold"}>
                      Cadastre-se
                    </Text>
                  </Link>
                </Stack>

                <Button
                  type="submit"
                  bg="#FAB929"
                  color="white"
                  _hover={{
                    bg: "#6E7C7C",
                    color: "white",
                  }}
                >
                  Entrar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>

      {/* MODAL */}
      <Modal isOpen={isOpen} onClose={handleClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Esqueci a senha</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl>
                <FormLabel>E-mail</FormLabel>
                <Input
                  value={emailForgot}
                  type="email"
                  placeholder="Digite seu e-mail"
                  onChange={(event) => setEmailForgot(event.target.value)}
                />
              </FormControl>
              <Button onClick={handleSubmitForgot} mt={4} colorScheme="blue">
                Enviar
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Login;
