import React, { useState, useContext } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  Text,
  Input,
  Button,
  useToast,
} from "@chakra-ui/react";
import { MateriaContext } from "../contexts/Materia";
// Componente para o modal de adicionar matéria
const AdicionarMateriaModal = ({ isOpen, onClose, onAdd }) => {
  const { createMateriaApi } = useContext(MateriaContext);
  const [nomeMateria, setNomeMateria] = useState("");
  const toast = useToast();

  const handleSubmit = async () => {
    const data = {
      title: nomeMateria,
    };
    try {
      const createMateria = await createMateriaApi(data);
      if (createMateria.status === 200) {
        toast({
          title: "Matéria adicionada com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    setNomeMateria("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Matéria</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text>Nome da Matéria:</Text>
            <Input
              type="text"
              value={nomeMateria}
              onChange={(e) => setNomeMateria(e.target.value)}
            />
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
            Adicionar
          </Button>
          <Button onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdicionarMateriaModal;
