import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  Input,
  Stack,
  Heading,
  Text,
  Button,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AuthContext } from "../../contexts/Auth";
import MenuPrincipal from "../../Components/Menu";
import BGLogin from "../../images/bg-login.png";
import InputMask from "react-input-mask";
import { UserContext } from "../../contexts/User";

const RegisterNewUser = () => {
  const navigate = useNavigate();
  const { createUserApi } = useContext(UserContext);
  const toast = useToast();
  const [userType, setUserType] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  const handleCpfChange = (event) => {
    setCpf(event.target.value);
  };

  const handleBirthdateChange = (event) => {
    setBirthdate(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const validatePassword = (password) => {
    // Verifica se a senha possui pelo menos uma letra maiúscula
    const uppercaseRegex = /[A-Z]/;
    // Verifica se a senha possui pelo menos um número
    const numberRegex = /[0-9]/;
    // Verifica se a senha possui pelo menos um caractere especial (pode adicionar mais se necessário)
    const specialCharacterRegex = /[@$!%*?&]/;
    // Verifica se a senha tem pelo menos 6 caracteres
    const lengthCheck = password.length >= 6;

    const isStrongPassword =
      uppercaseRegex.test(password) &&
      numberRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      lengthCheck;

    if (isStrongPassword) {
      setPasswordStrength("Senha Forte");
    } else {
      setPasswordStrength("Senha Fraca");
    }
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async () => {
    const data = {
      name: name,
      email: email,
      password: password,
      cpf: cpf,
      birthdate: birthdate,
      phone: phone,
      role_id: 3,
    };
    try {
      const createNewUser = await createUserApi(data);
      console.log(createNewUser);

      if (createNewUser.status === 200) {
        toast({
          title: "Usuário adicionado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      navigate("/login");
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  };

  return (
    <>
      <MenuPrincipal />
      <Box
        mt={"-28px"}
        bgImage={BGLogin}
        width={"99vw"}
        height={"350px"}
        backgroundSize={"cover"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Box color={"#FAB929"} fontSize={"28px"} textAlign={"center"}>
            <Text>Área Aluno</Text>
          </Box>
          <Box color={"white"} fontSize={"48px"} fontWeight={"medium"}>
            <Text>COLÉGIO NAVAL</Text>
          </Box>
        </Box>
      </Box>
      <Box bg="#050f40" p={8}>
        <Text
          color={"white"}
          fontSize={"28px"}
          fontWeight={"medium"}
          textAlign={"center"}
        >
          Faça seu cadastro
        </Text>
        <Box display={"block"}>
          <Flex
            direction={{ base: "column", md: "row" }}
            mx={"auto"}
            maxW={"lg"}
            py={2}
            px={6}
          >
            <VStack flex={1} align="stretch" spacing={4}>
              <FormControl color={"white"}>
                <Input
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={handleNameChange}
                />
              </FormControl>
              <FormControl color={"white"}>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </FormControl>
              <FormControl color={"white"}>
                <InputMask
                  mask="999.999.999-99"
                  value={cpf}
                  onChange={handleCpfChange}
                >
                  {(inputProps) => <Input {...inputProps} placeholder="CPF" />}
                </InputMask>
              </FormControl>
            </VStack>
            <VStack flex={1} align="stretch" spacing={4} ml={2}>
              <FormControl color={"white"}>
                <InputMask
                  mask="99/99/9999"
                  value={birthdate}
                  onChange={handleBirthdateChange}
                >
                  {(inputProps) => (
                    <Input {...inputProps} placeholder="Data de Nascimento" />
                  )}
                </InputMask>
              </FormControl>
              <FormControl color={"white"}>
                <InputMask
                  mask="(99) 99999-9999"
                  value={phone}
                  onChange={handlePhoneChange}
                >
                  {(inputProps) => (
                    <Input {...inputProps} placeholder="Telefone" />
                  )}
                </InputMask>
              </FormControl>
              <FormControl color={"white"}>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Senha"
                    size="lg"
                    borderColor={
                      passwordStrength === "Senha Forte"
                        ? "green.500"
                        : "red.500"
                    }
                  />
                  <InputRightElement width="4.5rem">
                    <Button onClick={handleShowPassword} colorScheme="red">
                      {showPassword ? "Ocultar" : "Mostrar"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text
                  color={
                    passwordStrength === "Senha Forte" ? "green.500" : "red.500"
                  }
                  fontSize="sm"
                  mt={2}
                >
                  A senha deve conter pelo menos uma letra maiúscula, um número,
                  um caractere especial e ter pelo menos 6 caracteres.
                </Text>
              </FormControl>
              <FormControl color={"white"}>
                <InputGroup>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder="Confirmar Senha"
                  />
                  <InputRightElement width="4.5rem">
                    <Button onClick={handleShowConfirmPassword}>
                      {showConfirmPassword ? "Ocultar" : "Mostrar"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </VStack>
          </Flex>
        </Box>
        <Box display={"block"} mt={5}>
          <Flex justifyContent={"center"}>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Cadastrar
            </Button>
          </Flex>
          <Flex justifyContent={"center"} color={"white"}>
            <Text>
              Já tem cadastro? <Link to="/login">Faça o login</Link>
            </Text>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default RegisterNewUser;
