import api from "../services/api";
import { createContext, useState } from "react";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [userApi, setUserApi] = useState(null);
  const createUserApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("create/user", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const updateUserApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`update/user/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    if (response.data) {
      setUserApi((prevUser) => ({
        ...prevUser,
        ...response.data, // Atualiza as informações do usuário
      }));
    }

    return response;
  };

  const listUsersApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("list/users", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const activateUserApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`activate/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const inactivateUserApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`inactivate/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const getUserApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.data) {
      setUserApi((prevUser) => ({
        ...prevUser,
        ...response.data,
      }));
    }
    return response;
  };

  const getUserResultsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`results/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const deleteUserApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const getUserAvatarApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get(`get-avatar`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  return (
    <UserContext.Provider
      value={{
        userApi,
        setUserApi,
        createUserApi,
        listUsersApi,
        activateUserApi,
        inactivateUserApi,
        getUserApi,
        updateUserApi,
        deleteUserApi,
        getUserResultsApi,
        getUserAvatarApi,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
