import api from "../services/api";
import { createContext } from "react";

export const BookContext = createContext({});

export const BookProvider = ({ children }) => {
  const createBookApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("create/book", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const listBookApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`list/notebooks/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const detailBookApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/notebook/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const updateBookApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`update/notebook/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };
  const deleteBookApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.delete(`delete/notebook/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  const createNotebookApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("create/notebook", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  const createAnswerQuestionApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("add/answer/user", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });

    return response;
  };

  return (
    <BookContext.Provider
      value={{
        createBookApi,
        listBookApi,
        detailBookApi,
        deleteBookApi,
        updateBookApi,
        createNotebookApi,
        createAnswerQuestionApi,
      }}
    >
      {children}
    </BookContext.Provider>
  );
};
