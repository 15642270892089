import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  useToast,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useContext, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { SimuladoContext } from "../../contexts/Simulado";
import { MateriaContext } from "../../contexts/Materia";

const SimuladoPage = () => {
  const { DetailSimuladoApi, deleteQuestaoSimuladoApi, addQuestaoSimuladoApi } =
    useContext(SimuladoContext);

  const { listQuestoesApi } = useContext(MateriaContext);

  const toast = useToast();

  const [simulator, setSimulator] = useState();
  const [questions, setQuestions] = useState();
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function getSimuladoInfo() {
    const simuladoID = localStorage.getItem("simulado_id");
    try {
      const simuladoInfoApi = await DetailSimuladoApi(simuladoID);
      console.log(simuladoInfoApi.data?.["simulator"]);
      setSimulator(simuladoInfoApi.data?.["simulator"]);
      setQuestions(simuladoInfoApi.data?.["questions"]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getQuestoes() {
    try {
      const questoesRes = await listQuestoesApi();
      console.log(questoesRes.data?.[0]);
      setQuestionsList(questoesRes.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getSimuladoInfo();
    getQuestoes();
  }, []);

  const handleSelectQuestion = (questionId, questionText) => {
    const isSelected = selectedQuestions.some(
      (question) => question.id === questionId
    );
    if (isSelected) {
      setSelectedQuestions(
        selectedQuestions.filter((question) => question.id !== questionId)
      );
    } else {
      setSelectedQuestions([
        ...selectedQuestions,
        { id: questionId, question: questionText },
      ]);
    }
  };

  const handleRemoveQuestion = (questionId) => {
    setSelectedQuestions(
      selectedQuestions.filter((question) => question.id !== questionId)
    );
  };

  const handleSubmit = async () => {
    const simuladoID = localStorage.getItem("simulado_id");

    const data = {
      questions: selectedQuestions.map((questionId) => ({
        question_id: questionId.id,
      })),
    };

    try {
      const simulado = await addQuestaoSimuladoApi(simuladoID, data);
      if (simulado.status === 200) {
        toast({
          title: "Questão adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title:
          "Verifique se você tentou adicionar uma questão já existente neste simulado",
        status: "error",
        isClosable: true,
      });
    }
    setSelectedQuestions([]);
    onClose();
    getSimuladoInfo();
  };
  const deleteQuestion = async (questionID) => {
    const simuladoID = localStorage.getItem("simulado_id");
    try {
      const deleteQuestion = await deleteQuestaoSimuladoApi(
        questionID,
        simuladoID
      );
      console.log(deleteQuestion.data);
      if (deleteQuestion.status === 200) {
        toast({
          title: "Questão removida do simulado",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getSimuladoInfo();
  };

  return (
    <>
      <Navbar />
      {simulator && (
        <Box p={8} ml={{ base: 0, md: 60 }}>
          <Flex justifyContent="space-between">
            <Heading as="h1" size="xl" mb={4}>
              {simulator.title}
            </Heading>
            <Button colorScheme="teal" mr={3} onClick={onOpen}>
              Adicionar nova questão
            </Button>
          </Flex>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap={4}
          >
            {questions.map((question) => (
              <Box
                key={question.id}
                borderWidth="1px"
                borderRadius="md"
                p={4}
                bg="white"
                boxShadow="md"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: question.question?.question,
                  }}
                ></span>

                {question.answers.map((answer) => (
                  <Box
                    key={answer.id}
                    p={2}
                    borderBottom={"1px solid #e9e9e9"}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text>{answer.answer}</Text>
                    {answer.answer_verify === 1 ? (
                      <Text color="green.500">Resposta correta</Text>
                    ) : (
                      <Text color="red.500">Resposta incorreta</Text>
                    )}
                  </Box>
                ))}
                <Center>
                  <Button
                    mt={4}
                    colorScheme="red"
                    onClick={() => {
                      deleteQuestion(question.question_id);
                    }}
                  >
                    Excluir Questão
                  </Button>
                </Center>
              </Box>
            ))}
          </Grid>
        </Box>
      )}

      {questionsList && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent width={"1200px"} maxW={"90%"}>
            <ModalHeader>Adicionar Simulado</ModalHeader>
            <ModalBody>
              <VStack spacing={4} align="stretch">
                <Text>Selecione as Questões:</Text>
                <Select
                  value=""
                  onChange={(e) => {
                    handleSelectQuestion(
                      e.target.value,
                      e.target.options[e.target.selectedIndex].text
                    );
                  }}
                >
                  <option value="" disabled>
                    Selecione uma questão
                  </option>
                  {questionsList?.map((question) => (
                    <option
                      key={question.id}
                      value={question.id}
                      dangerouslySetInnerHTML={{
                        __html: question.question?.question,
                      }}
                    ></option>
                  ))}
                </Select>
                {selectedQuestions.map((question) => (
                  <Box key={question.id} display="flex" alignItems="center">
                    <Text
                      mr={2}
                      dangerouslySetInnerHTML={{
                        __html: question.question,
                      }}
                    ></Text>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleRemoveQuestion(question.id)}
                    >
                      x
                    </Button>
                  </Box>
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
                Adicionar
              </Button>
              <Button onClick={onClose}>Cancelar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SimuladoPage;
