import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import AdicionarMateriaModal from "../../Components/ModalAddMateria";
import { MateriaContext } from "../../contexts/Materia";

// Componente para exibir a lista de matérias
const Materias = () => {
  const toast = useToast();
  const { listMateriaApi, deleteMateriaApi } = useContext(MateriaContext);
  const [materias, setMaterias] = useState();
  const [selectedMateria, setSelectedMateria] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = JSON.parse(localStorage.getItem("user"));
  const roleId = user?.role_id;
  async function getMateriasList() {
    try {
      const materiasInfoApi = await listMateriaApi();
      console.log(materiasInfoApi.data?.[0]);
      setMaterias(materiasInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMateriasList();
  }, []);

  const navigate = useNavigate();

  const handleSelectMateria = (materia, id) => {
    localStorage.setItem("matery_id", id);
    navigate(`/materia/${materia}`);
  };

  const deleteMateria = async (id) => {
    try {
      const deleteMateria = await deleteMateriaApi(id);
      console.log(deleteMateria.data);
      if (deleteMateria.status === 200) {
        toast({
          title: "Materia deletada com Sucesso",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
    }
    getMateriasList();
  };

  return (
    <>
      <Navbar />
      {materias && (
        <Box
          p={8}
          ml={{ base: 0, md: 60 }}
          bgColor={"#f0f8ff"}
          minH={"100vh"}
          mt={"-35px"}
        >
          <Flex justifyContent={"space-between"}>
            <Heading as="h1" size="xl" mb={4}>
              Lista de Matérias
            </Heading>
            <Button colorScheme="teal" mb={4} onClick={onOpen}>
              Adicionar Matéria
            </Button>
          </Flex>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Nome da Matéria</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {materias?.map((materia) => (
                <Tr key={materia.id}>
                  <Td>{materia.matery}</Td>
                  <Td>
                    <Button
                      colorScheme="teal"
                      onClick={() =>
                        handleSelectMateria(materia.matery, materia.id)
                      }
                    >
                      Acessar Página
                    </Button>
                    {roleId === 1 && (
                      <Button
                        ml={5}
                        colorScheme="red"
                        onClick={() => deleteMateria(materia.id)}
                      >
                        Excluir
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      <AdicionarMateriaModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          getMateriasList();
        }}
      />
    </>
  );
};

export default Materias;
